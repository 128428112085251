.container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(10rem, 1fr);
    --gap-size: 1.2rem;
    --dot-size: 0.3rem;
    column-gap: var(--gap-size);
    row-gap: var(--gap-size);
    overflow: auto;
}
.gridKnot{
    position: relative;
    transform: translateY(0px);
    opacity: 1;
    padding: 1.25rem 0.625rem;
}
.gridKnot::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + var(--gap-size) / 2);
    height: calc(100% + var(--gap-size) / 2);
    background-image: linear-gradient(to right, #cccccb 0%, #cccccb calc(100% - var(--gap-size) / 2)), linear-gradient(to bottom, #cccccb 0%, #cccccb calc(100% - var(--gap-size) / 2));
    background-position: bottom left, top right;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: calc(100% - var(--gap-size) / 2) 1px, 1px calc(100% - var(--gap-size) / 2);
    z-index: -1;
}
.gridKnot:nth-child(3n+1):nth-last-child(-n+3)::before, 
    .gridKnot:nth-child(3n+1):nth-last-child(-n+3) ~ .gridKnot::before{
        background-image: linear-gradient(to bottom, #cccccb 0%, 0%);
        background-position: top right;
        background-size: 1px calc(100% - var(--gap-size) / 2);
}
.gridKnot:nth-child(3n)::before {
    background-image:linear-gradient(to bottom, #cccccb 0%, #cccccb calc(100% - var(--gap-size) / 2));
    background-position: bottom left, top right;
    background-size: calc(100% - var(--gap-size) / 2) 1px, 1px calc(100% - var(--gap-size) / 2);
}
.gridKnot::after{
    content: '';
    position: absolute;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: 50%;
    background: #cccccb;
    top: calc(100% - var(--dot-size) / 2 + var(--gap-size) / 2);
    left: calc(100% - var(--dot-size) / 2 + var(--gap-size) / 2);
}
.gridKnot:nth-child(3n+1):nth-last-child(-n+3)::after, 
    .gridKnot:nth-child(3n+1):nth-last-child(-n+3) ~ .gridKnot::after{
        background: none;
}
.gridKnot:nth-child(3n)::after {
    background: none;
}
.commentsContainer{
    display: flex;
    width: 100%;
    height:100%;
    flex-direction: column;
}
.content{
    font-size: 32px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 500;
    color: #000000;
    display: flex;
    width: 80%;
    gap: 0.5rem;
}
.content span{
    color: #333333;
    font-size: 16px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 500;
    line-height: 24px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.filterContainer{
    height: 18%;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    padding: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
}
.col1{
    display: flex;
    width: 12%;
    font-size: 16px;
    color: #2B5580;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
}
.col2{
    display: flex;
    width: 50%;
}
.col3{
    display: flex;
    width: 22%;
    gap: 10px;
}
.col4{
    display: flex;
    width: 16%;
    justify-content: flex-end;
}
.commentsWrapper{
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 18px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    margin: 30px 0px;
    flex-direction: column;
}
.cardContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.cardCol1{
    width: 10%;
}
.dot{
    width: 0.75rem;
    height: 12px;
    background: #12A195;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 20px;
}
.cardCol2{
    width: 80%;
}
.theme{
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    border-radius: 4px;
    height: 28px;
    border: 1px transparent;
    background: #F8F8F8;
    padding: 4px 8px;
    gap: 5px;
    align-items: center;
    color: #333333;
    font-size: 12px;
    font-family: "MckinseySans-Regular", sans-serif;
    margin-bottom: 20px;
}
.cardCol3{
    width: 10%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    cursor: pointer;
}
.cardTags{
    display: flex;
    align-items: center;
    width: 100%;
}
.text{
    color: #000000;
    font-size: 14px;
    font-family: "MckinseySans-Light", sans-serif;
    text-align: left;
    padding-bottom: 10px;
    line-height: 20px;
}
.infoContainer{
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
}
.legendsCol{
    width: 50%;
    display: flex;
    justify-content: flex-start;
}
.paginationCol{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 1px;
}
.left{
    width: 48px;
    height: 36px;
    border-radius: 20px 0px 0px 20px;
    padding: 10px 12px 10px 20px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    rotate: 0deg;
}
.right{
    width: 48px;
    height: 36px;
    border-radius: 0px 20px 20px 0px;
    padding: 10px 20px 10px 12px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    
    rotate: 0deg;
}
.titleContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
}
.filter{
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    font-family: "MckinseySans-Regular", sans-serif;
    cursor: pointer;
    align-items: center;
    background: none;
    border: none;
}
.filterLoader{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.readMoreButton{
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
    color: #2B5580;
    text-decoration: underline;
    cursor: pointer;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    line-height: 20px;
}
.button{
    border: none;
    background: none;
}
.close{
    border: none;
    background: none;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}
.translatingMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    flex-direction: column;
    gap: 2.5rem;
}
.circularSpinner {
    position: relative;
    width: 20px; /* Adjust size here */
    height: 20px; /* Adjust size here */
  }
  
  .spinnerBar {
    position: absolute;
    width: 4px; /* Bar width */
    height: 8px; /* Bar height */
    background-color: #000; /* Bar color */
    border-radius: 3px; /* Rounded edges */
    transform-origin: 10px 10px; /* Center alignment */
    animation: fade 1.2s linear infinite;
  }
  
  .spinnerBar:nth-child(1) {
    transform: rotate(0deg) translate(0, -14px);
    animation-delay: -1.1s;
  }
  .spinnerBar:nth-child(2) {
    transform: rotate(30deg) translate(0, -14px);
    animation-delay: -1s;
  }
  .spinnerBar:nth-child(3) {
    transform: rotate(60deg) translate(0, -14px);
    animation-delay: -0.9s;
  }
  .spinnerBar:nth-child(4) {
    transform: rotate(90deg) translate(0, -14px);
    animation-delay: -0.8s;
  }
  .spinnerBar:nth-child(5) {
    transform: rotate(120deg) translate(0, -14px);
    animation-delay: -0.7s;
  }
  .spinnerBar:nth-child(6) {
    transform: rotate(150deg) translate(0, -14px);
    animation-delay: -0.6s;
  }
  .spinnerBar:nth-child(7) {
    transform: rotate(180deg) translate(0, -14px);
    animation-delay: -0.5s;
  }
  .spinnerBar:nth-child(8) {
    transform: rotate(210deg) translate(0, -14px);
    animation-delay: -0.4s;
  }
  .spinnerBar:nth-child(9) {
    transform: rotate(240deg) translate(0, -14px);
    animation-delay: -0.3s;
  }
  .spinnerBar:nth-child(10) {
    transform: rotate(270deg) translate(0, -14px);
    animation-delay: -0.2s;
  }
  .spinnerBar:nth-child(11) {
    transform: rotate(300deg) translate(0, -14px);
    animation-delay: -0.1s;
  }
  .spinnerBar:nth-child(12) {
    transform: rotate(330deg) translate(0, -14px);
    animation-delay: 0s;
  }
  
  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
  .buttonContainer{
    display: flex;
    flex-direction: row;
    width: 20%;
    gap: 24px;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

  }
  .exportButton{
    color: #082644;
    text-align: center;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }
  .export{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
  }
  .separator{
    height: 1.25rem;
    border: 1px solid #E6E6E6;
  }
  
  