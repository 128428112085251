.container{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
}
.content{
    font-size: 32px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 500;
    color: #000000;
    display: flex;
    width: 100%;
}
.titleContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
}
.wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 24px 24px 50px 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    position: relative;
}
.sentimentBreakDownContainer{
    display: flex;
    height: 360px;
    margin-bottom: 30px;
    gap: 20px;
}
.sentimentBreakdownText{
    font-size: 20px;
    font-weight: 500;
    font-family: "MckinseySans-Medium", sans-serif;
    line-height: 32px;
    margin-top: 10px;
    display: flex;
}
.chartContainer{
    display: flex;
    padding: 20px 0px;
    align-items: center;
    height: 100%;
    gap: 30px
}
.comments{
    display: flex;
    flex-direction: row;
}
.sentiments{
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    --gap-size: 1.5rem;
    row-gap: var(--gap-size); 
}
.gridKnot{
    position: relative;
    transform: translateY(0px);
    opacity: 1;
    padding:  20px 30px;
}
.gridKnot::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + var(--gap-size) / 2);
    height: calc(100% + var(--gap-size) / 2);
    background-image: linear-gradient(to right, #E6E6E6 0%, #E6E6E6 calc(100% - var(--gap-size) / 2)), linear-gradient(to bottom, #E6E6E6 0%, #E6E6E6 calc(100% - var(--gap-size) / 2));
    background-position: bottom left, top right;
    background-repeat: no-repeat;
    background-size: calc(100% - var(--gap-size) / 2) 1px, 1px calc(100% - var(--gap-size) / 2);
    z-index: 0;
}
.gridKnot:nth-child(2n)::before {
    background-image: linear-gradient(to bottom, #E6E6E6 0%, #E6E6E6 0%);
    background-position: bottom left, top right;
    background-size: calc(100% - var(--gap-size) / 2) 1px, 1px calc(100% - var(--gap-size) / 2);
}
.gridKnot:last-child::before {
    background-image: linear-gradient(to right, #E6E6E6 0%, #E6E6E6 0%);
    background-size: 0px 0px;
}
.gridKnot:nth-child(2n+1):nth-last-child(-n+3)::before, 
    .gridKnot:nth-child(3n+1):nth-last-child(-n+3) ~ .gridKnot::before{
        background-image: linear-gradient(to bottom, #E6E6E6 0%, 0%);
        background-position: top right;
        background-size: 1px calc(100% - var(--gap-size) / 2);
}
.dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 10px;
}
.gridElement{
    display: flex;
    margin-bottom: 10px;
}
.number{
    display: flex;
    margin-left: 20px;
    font-size: 24px;
    font-family: "MckinseySans-Medium", sans-serif;
    font-weight: 500;
    line-height: 28px;
    color: #333333;
}
.sentimentText{
    font-size: 16px;
    font-family: "MckinseySans-Medium", sans-serif;
    font-weight: 500;
    line-height: 24px;
    color: #333333;
    margin-right: 5px;
}
.commentText{
    font-size: 16px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
}
.sentimentScore{
    display: flex;
    padding: 30px 0px;
    font-size: 24px;
    font-family: "MckinseySans-Medium", sans-serif;
    font-weight: 500;
    line-height: 28px;
}
.separator{
    border: 1px solid #E6E6E6;
    margin: 10px 0px;
}
.loader{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.buttonContainer {
    position: absolute;
    bottom: 18px;
    right: 30px;
    font-size: 14px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
    color: #2B5580;
    text-decoration: underline;
    cursor: pointer;
}
.gridContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}
.button{
    display: flex;
    justify-content: center;
    gap: 8px;
}
.paginationCol{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1px;
}
.left{
    width: 48px;
    height: 36px;
    border-radius: 20px 0px 0px 20px;
    padding: 10px 12px 10px 20px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    rotate: 0deg;
}
.right{
    width: 48px;
    height: 36px;
    border-radius: 0px 20px 20px 0px;
    padding: 10px 20px 10px 12px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    
    rotate: 0deg;
}
.filter{
    display: flex;
    width: 10%;
    justify-content: flex-end;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    font-family: "MckinseySans-Regular", sans-serif;
    cursor: pointer;
    background: none;
    border: none;
}