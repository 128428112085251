.close{
    border: none;
    background: none;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}
.text{
    color: var(--Neutral-Black, #000);
    text-align: center;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; 
}
.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.subText{
    color: var(--Neutral-Black, #000);
    text-align: center;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.languageText{
    color: var(--Neutral-Black, #000);
    font-family: "McKinseySans-Medium", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.titleContainer{
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    margin-bottom: 40px;
}
.titleText{
    color: var(--Feedback-Solid-Informational, #0679C3);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 40px;
}
.contentContainer{
    display: flex;
    width: 100%;
    gap: 40px;
    padding-left: 36px;
}
.userFieldsContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 40px;
    padding-left: 36px;
}
.surveyContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}
.fieldsContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.label{
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.label::after {
    content: "*";
    color: red;
}
.noAsterisk::after {
    content: "";
}
.assignClose{
    border: none;
    background: none;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    padding-top: 16px;
    padding-right: 16px;
}
.textField{
    outline: none;
    display: flex;
    width: 370px;
    height: 36px;
    padding: 0px 16px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid  #ccc;
    background: var(--Neutral-White, #FFF);
}
.surveyClose{
    border: none;
    background: none;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    padding-top: 8px;
    padding-right: 8px;
}
.detailsContainer{
    display: flex;
    padding-left: 36px;
    gap: 24px;
}
.fields{
    padding: 0 36px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-items: center;
    gap: 24px;
}
.headingContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.headingText{
    color: var(--Text-Disabled, #B3B3B3);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.headingValue{
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.error{
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0 36px;
    color: var(--Text-Danger, #D51F31);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}