.container {
    display: flex;
    align-items: center;
  }
  
  .label {
    margin-right: 8px;
    color: #757575;
    font-weight: 500;
    font-family: "MckinseySans-Medium", sans-serif;
    font-size: 12px;
  }
  
  .legend {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .legendItem {
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
  }
  
  .colorBox {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    border-radius: 2px;
  }
  
  .negative .colorBox {
    background-color: #FF7A9F;
  }
  
  .neutral .colorBox {
    background-color: #E6E6E6;
  }
  
  .positive .colorBox {
    background-color: #12A195;
  }
  