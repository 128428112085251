.card{
    width: 100%;
    height: 100%;
    box-shadow: 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    border-radius: 8px;
    border: 1px rgba(5, 28, 44, 0.15);
}
.cardHeader{
    background: #E6F2F9;
    height: 25%;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000000;
    font-size: 18px;
    font-family: "MckinseySans-Medium", sans-serif;
    padding-left: 24px;
}
.cardContent{
    display: flex;
    padding: 24px;
    height: 75%;
    flex-direction: column;
}
.cardDetails{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
}
.row1{
    display: flex;
    width: 45%;
    gap: 10px;
    flex-direction: column;
}
.row2{
    display: flex;
    width: 55%;
    gap: 10px;  
    flex-direction: column;
}
.textStyle{
    font-size: 14px;
    color: #757575;
    font-family: "MckinseySans-Regular", sans-serif;
}
.innerContainer{
    display: flex;
    gap: 10px;
}
.text1{
    display: flex;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
}
.text2{
    display: flex;
    font-size: 18px;
    font-family: "MckinseySans-Medium", sans-serif;
}
.separator{
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
}
.cardFooter{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #2B5580;
    font-size: 14px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
    gap: 8px;
    margin-bottom: 30px;
}
.customCard{
    display: flex;
    width: 100%;
    height: 200px;
    flex-shrink: 0;
    box-shadow: 0px 2px 4px -1px rgba(5, 28, 44, 0.20), 0px 0px 1px 0px rgba(5, 28, 44, 0.15);
    border-radius: 8px;
    background: var(--Neutral-White, #FFF);
    flex-direction: column;
    padding-bottom: 24px;
}
.container{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr minmax(200px, auto);
    padding: 24px;
    gap: 24px;
    align-items: flex-start;
    flex-shrink: 0;
    justify-content: space-around;
}
.header{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
}
.text{
    color: var(--Text-Subtle, #757575);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}
.rowText{
    display: flex;
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Medium", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.editContainer{
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    padding-bottom: 16px;
}
.editText{
    color: var(--Secondary-DeepBlue-500, #2B5580);
    text-align: center;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}
.editButton{
    background: none;
    border: none;
    cursor: pointer;
    gap: 8px;
    display: flex;
}