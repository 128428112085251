.appbar {
    height: 75px;
    padding: 20px;
    background-color: #051c2c;
    color: white;
    display: flex;
    z-index: 1201;
    align-items: center;
    width:100%;
}
.nav{
    float: left;
    margin-left: 80px;
}
.headerLink {
    text-decoration: none;
    color: #ffffff;
}
.headerLink:link, 
.headerLink:visited,  
.headerLink:hover, 
.headerLink:active {
    text-decoration: none;
    color: #ffffff;
}
.activeHeaderLink {
    border-bottom: 2px solid #ffffff;
    font-weight: bold;
    padding-bottom: 26px;
}
.navLink{
    margin: 20px;
    font-size: 16px;
    cursor: pointer;
    font-family: "McKinseySans-Light", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; 
}
.disabledLink{
    pointer-events: none;
    color: gray;
    cursor: none
}