.snackbar {
    visibility: hidden;
    min-width: 22rem;
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 16px;
    position: fixed;
    z-index: 1;
    bottom: 30px;
    transition: visibility 0s, opacity 0.5s linear;
    opacity: 0;
    box-shadow: 0px 16px 38px -1px rgba(5, 28, 44, 0.40), 0px 0px 3px 0px rgba(5, 28, 44, 0.16);

}

.snackbar.show {
    visibility: visible;
    opacity: 1;
}

.close {
    background: none;
    border: none;
    color: black;
    font-size: 20px;
    margin-left: 16px;
    cursor: pointer;
}
.container{
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.title{
    font-family: "MckinseySans-Medium", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #333333;
}
.text{
    font-family: "McKinseySans-Light", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; 
    color: var(--Neutral-80, #333);
    text-align: left;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}
.titleTextContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}
.summaryBtn{
    background: none;
    border: none;
    color:  #2B5580;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
}

