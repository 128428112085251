.sliderContainer {
    width: 80%;
    text-align: center;
    position: relative;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #F2F2F2;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    border-radius: 5px;
    box-shadow: inset 0px 0px 8px rgba(98, 153, 181, 0.2);
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px; 
    height: 24px;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }
  
  .slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }
  
  .sliderLabels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    color: #757575;
    font-weight: 300;
    font-family: "MckinseySans-Regular", sans-serif;
  }
  