.navContainer {
    display: flex;
    background-color: #f3f6fa;
    padding: 15px 15px 0px 30px;
    height: 4.05rem;
  }
  
  .navItem {
    color: #3b3b3b;
    text-decoration: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: normal;
  }
  
  .navItem:hover {
    background-color: #e0e6ed;
  }
  
  .activeNavItem {
    color: #051c2c;
    background-color: white;
    border-radius: 5px;
    padding: 10px 32px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
  }
  
  .icon {
    margin-right: 8px;
    font-size: 16px;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .routeContainer{
    display: flex;
    width: 100%;
    padding: 0px 60px 60px 60px;
  }
  