* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  min-height: 100vh;
  font-family: "McKinseySans-Regular", "Arial", sans-serif;
  width: 100%;
}
@font-face {
  font-family: "McKinseySans-Regular";
  src:
      local("McKinseySans-Regular"),
      url("./fonts/McKinseySans-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "McKinseySans-Light";
  src:
      local("McKinseySans-Light"),
      url("./fonts/McKinseySans-Light.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "McKinseySans-Medium";
  src:
      local("McKinseySans-Medium"),
      url("./fonts/McKinseySans-Medium.otf") format("opentype");
  font-weight: bolder;
  font-style: normal;
}