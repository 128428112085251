.message{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    color: #0b1b2b;
    height: 60vh;
    font-family: "MckinseySans-Regular", sans-serif;
}
.message .title {
    padding-top: 10px;
    font-size: 25px;
    font-weight: 700;
    color: #333333;
    margin-top: 30px;
}
.subtitle {
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #757575;
}

