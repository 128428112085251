.imageUploader{
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid rgba(130, 166, 201, 1)
}
.imagePreviewWrapper {
    position: relative;
    display: inline-block;
  }
  
  .closeButton {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    background-color: #1f3a5a; /* Dark blue background */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .closeIcon {
    color: white;
    font-size: 16px; /* Adjust the size of the icon */
  }
  