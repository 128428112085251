.navContainer {
    display: flex;
    background-color: #f3f6fa;
    padding: 15px 15px 0px 15px;
    height: 10vh;
  }
  
  .navItem {
    color: #3b3b3b;
    text-decoration: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: normal;
  }
  
  .navItem:hover {
    background-color: #e0e6ed;
  }
  
  .activeNavItem {
    color: #051c2c;
    background-color: white;
    border-radius: 5px;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
  }
  
  .icon {
    margin-right: 8px;
    font-size: 16px;
  }
  