.surveyContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  
  .content {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 5%
  }
  .buttonContainer{
    display: flex;
    justify-content: center;
    align-items: end;
    transform: rotate(-90deg);
    height: 100px;
    width: 100px;
    gap: 20px;
  }
  .icon{
    transform: rotate(90deg);
    display: flex;
  }
  .mainContent{
    width: 100%;
    display: flex;
    flex: 1;
    height: 90vh;
    flex-direction: row-reverse;
  }
  .wordButtonContainer{
    position: fixed;
    right: -6vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: rotate(-90deg);
    z-index: 1;
    top:70%;
  }
  .routeContainer{
    display: flex;
    width: 95%;
    padding-top: 30px;
    padding-left: 40px;
  }