.container{
    display: flex;
    width: 100%;
    height:100%;
    flex-direction: column;
}
.content{
    font-size: 32px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 500;
    color: #000000;
    display: flex;
    width: 90%;
}
.filterContainer{
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    padding: 34px 42px;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
}
.col1{
    display: flex;
    width: 12%;
    font-size: 16px;
    color: #2B5580;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
}
.col2{
    display: flex;
    align-items: flex-start;
    width: 23%;
}
.col3{
    display: flex;
    width: 18%;
}
.col4{
    display: flex;
    width: 32%;
    gap: 10px;
    justify-content: center;
}
.col5{
    display: flex;
    width: 15%;
    justify-content: flex-end;
}
.col5{
    display: flex;
    width: 16%;
    justify-content: flex-end;
}
.dropdownContainer{
    display: flex;
    margin-top: 30px;
}
.questionContainer{
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 18px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    margin: 30px 0px;
}
.questionCard{
    display: flex;
    border: 1px solid #E6E6E6;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    flex-direction: column;
}
.questionCardHeader{
    width: 100%;
    background: #E6F2F9;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000000;
    font-size: 18px;
    font-family: "MckinseySans-Medium", sans-serif;
    padding-left: 16px;
    gap: 20px;
}
.tag{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    color: #333333;
}
.questionCardContent{
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.keywordsContainer{
    border-radius: 4px;
    height: 28px;
    width: fit-content;
    border: 1px transparent;
    background: #F8F8F8;
    padding: 4px 8px;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #333333;
    font-size: 12px;
    font-family: "MckinseySans-Regular", sans-serif;
    margin-bottom: 20px;
}
.title{
    font-weight: 600;
    font-family: "MckinseySans-Medium", sans-serif;
}
.keywords{
    font-weight: 400;
}
.summary{
    font-size: 14px;
    font-weight: 300;
    font-family: "MckinseySans-Light", sans-serif;
    color: #000000;
    line-height: 20px;
    text-align: left;
}
.image{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}
.image img{
    position: absolute;
    bottom: 0;
}
.sentimentMarker{
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: 2px;
}
.sentimentContainer{
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
.sentimentText{
    color: #757575;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    font-family: "MckinseySans-Medium", sans-serif;
}
.customHr{
    margin: 20px 0px;
    border: 1px solid #E6E6E6;
}
.loader{
    height: 40vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.question{
    text-align: left;
    width: 70%;
}
.summaryLoaderWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 1rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2.5rem;
}
.loaderAnimation {
    position: relative;
    width: 0.9rem;
    height: 0.9rem;
    margin: 0.8em;
    border-radius: 50%;
  }
  
  @keyframes anime {
    50%,
    75% {
      transform: scale(2.5);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  
  .loaderAnimation:nth-child(1) {
    background-color: #b1ddf4;
  }
  .loaderAnimation:nth-child(2) {
    background-color: #6ecbf7;
  }
  .loaderAnimation:nth-child(3) {
    background-color: #34b4f4;
  }
  .loaderAnimation:nth-child(4) {
    background-color: #0291dc;
  }
  .loaderAnimation:nth-child(5) {
    background-color: #0679c3;
  }
  
  .loaderAnimation::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: anime 2s ease-out infinite;
  }
  
  .loaderAnimation:nth-child(1)::before {
    animation-delay: 0.2s;
  }
  .loaderAnimation:nth-child(2)::before {
    animation-delay: 0.4s;
  }
  .loaderAnimation:nth-child(3)::before {
    animation-delay: 0.6s;
  }
  .loaderAnimation:nth-child(4)::before {
    animation-delay: 0.8s;
  }
  .loaderAnimation:nth-child(5)::before {
    animation-delay: 1s;
  }
  .titleContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
}
.filter{
    display: flex;
    width: 10%;
    justify-content: flex-end;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    font-family: "MckinseySans-Regular", sans-serif;
    cursor: pointer;
    align-items: center;
    background: none;
    border: none;
}
.filterLoader{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.text{
    font-family: Bower;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    color: #333;
}
.subText{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #757575;
    font-family: "MckinseySans-Regular", sans-serif;
}