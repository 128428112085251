.customSelect {
    position: relative;
    font-size: 14px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 400;
    display: inline-block;
    align-items: center;
    text-align: left;
    color: #333;
  }
  