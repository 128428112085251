.table{
    font-family: "MckinseySans-Regular", sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #E6E6E6;
}
.tableHeader{
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    font-family: "MckinseySans-Regular", sans-serif;
}
.tableHeaderRow{
    color: #333333;
    font-family: "MckinseySans-Medium", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 8px 12px;
    border: 1px solid #E6E6E6;
}
.tableCell{
    color: #333333;
    font-size: 14px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 400;
    text-align: left;
    padding: 12px;
    border: 1px solid #E6E6E6;
}
.headerContent{
    display: flex;
    justify-content: space-between;
}
.sortIcon{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.container{
    overflow: auto;
}
.customTable {
    border-collapse: collapse;
    width: 100%;
    border: none;
    border-radius: 8px;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 2px 4px -1px rgba(5, 28, 44, 0.20), 0px 0px 1px 0px rgba(5, 28, 44, 0.15);
  }
  
  .customTable td {
    border-right: 1px solid var(--Neutral-10, #E6E6E6);
    border-bottom: 1px solid var(--Neutral-10, #E6E6E6);
    text-align: left;
    padding: 10px 16px;
  }
  .customTable th{
    border-right: 1px solid var(--Neutral-10, #E6E6E6);
    background: var(--Secondary-Cyan-100, #E6F2F9);
    text-align: left;
    padding: 10px 16px;
    border-bottom: 1px solid #333;
    color: var(--Neutral-80, #333);
    font-family: "McKinseySans-Medium", san;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .customTable tr:last-child td {
    border-bottom: none;
  }
  .customTable td:last-child,
  .customTable th:last-child {
    border-right: none;
}
.customTable th:first-child {
    border-top-left-radius: 8px;
  }
  
.customTable th:last-child {
    border-top-right-radius: 8px;
}
.customTable td:nth-child(1) {
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.customTable td:nth-child(2), 
.customTable td:nth-child(3),
.customTable td:nth-child(4),
.customTable td:nth-child(5),
.customTable td:nth-child(6),
.customTable td:nth-child(7) {
    color: var(--Text-Subtle, #757575);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.viewButton{
    border: none;
    background: none;
    color: var(--Secondary-DeepBlue-500, #2B5580);
    text-align: center;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    gap: 8px;
    display: flex;
    align-items: center;
}
.deleteButton{
    border: none;
    background: none;
}
.sort{
    float: right;
}
