.searchContainer{
    display: flex;
    width: 18.875rem;
    height: 1.75rem;
    padding: 0rem 1rem;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 1.375rem;
    border: 1px solid var(--Neutral-30, #B3B3B3);
}
.searchInput{
    border: none;
    outline: none;
    color: var(--Text-Subtle, #757575);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 15.25rem;
}
.textInputContainer{
    outline: none;
    display: flex;
    width: 23.125rem;
    height: 2.25rem;
    padding: 0px 16px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid  #ccc;
    background: var(--Neutral-White, #FFF);
}
.textInput{
    outline: none;
    border: none;
    background: none;
    width: 19.5rem;
}
.button{
    border: none;
    background: none;
}