.headerContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}
.header{
    display: flex;
    justify-content: flex-start;
    width: 70%;
    font-size: 1.25rem;
    line-height: 2rem;
    font-family: "MckinseySans-Medium", sans-serif;
    font-weight: 500;
    color: #000000;
}
.close{
    display: flex;
    justify-content: flex-end;
    width: 30%;
    cursor: pointer;
}
.bottom{
    height: 10vh;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 0 8px -1px rgba(5, 28, 44, .2);
    display: flex;
    padding: 1.5rem;
}
.container{
    padding: 0 40px;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 25px;
}
.demo{
    width: 70%;
    font-size: 18px;
    line-height: 28px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 400;
    color: #000000;
}
.right{
    width: 30%;
    display: flex;
    justify-content: flex-end;
}
.searchHead {
    margin: 1.25rem 0;
    border-radius: 20px;
    padding: 0 1rem;
    position: relative;
    border: 1px solid #b3b3b3;
}
.searchInput{
    border: none;
    outline: none;
    color: #757575;
    font-size: 14px;
    font-family: "MckinseySans-Regular", sans-serif;
    flex: 1;
    font-weight: 400;
    height: 26px;
}
.searchIcon {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
}
.numericalBadge{
    width: fit-content;
    height: 20px;
    padding: 0 5.5px;
    background: #E6F2F9;
    border-radius: 2px;
    color: #051C2C;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    line-height: 20px;
    font-family: "MckinseySans-Regular", sans-serif;
}
.left{
    display: flex;
}
.activeContainer{
    gap: 20px;
}
.separator{
    border: 0.5px solid #E6E6E6;
    width: 100%;
}
.preset{
    display: flex;
    width: 40%;
    justify-content: flex-start;
    align-items: center;
}
.buttonGroup{
    display: flex;
    width: 60%;
    justify-content: flex-end;
    gap: 20px;
}
.threshold{
    padding: 0px 40px 25px 40px;
    display: flex;
    flex-direction: row;
    font-size: 10px;
    gap: 15px;
    color: #757575;
    line-height: 16px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
}
.text{
    font-size: 18px;
    line-height: 28px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 400;
    color: #000000;
}
.badge{
    position: absolute;
    top: 0;
    left: 0; 
    z-index: 10;
    width: 100%;
    box-shadow: 0px 0px 1px 0px #051C2C26,
                0px 2px 4px -1px #051C2C33;
    padding: 1rem;
}
.align{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}
.infoIcon{
    width: 6%;
}
.infoText{
    width: 84%;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family:  "MckinseySans-Medium", sans-serif;
    font-weight: 500;
    color: #333333;
}
.removeIcon{
    width: 10%;
    display: flex;
    justify-content: flex-end;
}
.subText{
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family:  "MckinseySans-Regular", sans-serif;
    font-weight: 400;
    color: #333333;
    padding-top: 0.5rem;
    padding-left: 1.75rem;
}
.infoButton{
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-left: 0.5rem;
}
.textInput{
    width: 23rem;
    padding: 10px;
    margin-top: 0.375rem;
    border: 1px solid #A3A3A3; 
}
.textField{
    padding-left: 1.75rem;
}
.presetSelectedContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.presetIconContainer{
    display: flex;
    gap: 20px;
    width: 20%;
}
