.container{
    display: inline-flex;
    width: 100%;
    line-height: 4vh;
    height: 6px;
}
.container span {
    position: relative;
    display: flex;
    background: #fff;
    color: #757575;
    padding-left: .5vw;
    font-weight: 500;
    font-size: 1.5vh;
    height: 0.8vh;
    padding-right: 1vw;
  }
  
  .container span::after {
    content: "";
    height: 7px;
    width: 1px;
    background-color: #ccc;
    display: block;
    position: absolute;
    top: 95%;
    left: 0;
  }