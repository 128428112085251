.content{
    font-size: 32px;
    font-family: "MckinseySans-Regular", sans-serif;
    font-weight: 500;
    color: #000000;
    display: flex;
    width: 90%;
    justify-content: flex-start;
}
.container{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
}
.legendsContainer{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}
.titleContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
}
.filter{
    display: flex;
    width: 10%;
    justify-content: flex-end;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    font-family: "MckinseySans-Regular", sans-serif;
    cursor: pointer;
    background: none;
    border: none;
}