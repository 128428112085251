.container{
    width: 100%;
    padding-top: 38px;
}
.userTable{
    width: 100%;
}
.inputContainer{
    display: flex;
    margin-bottom: 26px;
}
.search{
    display: flex;
    justify-content: flex-start;
    width: 90%;
}
.buttonContainer{
    display: flex;
    justify-content: flex-end;
    width: 40%;
    gap: 1.5rem;
}
.pagination{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}
.loader{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.button{
    display: flex;
    gap: 8px;
    border: none;
    background: none;
    align-items: center;
    justify-content: center;
    color: #2B5580;
    text-align: center;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}