.tagContainer{
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 4px 8px;
    height: 24px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    font-family: "MckinseySans-Regular", sans-serif;
    cursor: pointer;
}
.active{
    background: #CCCCCC;
    width: fit-content;
    display: flex;
    gap: 5px;
    padding: 3px 6px;
    align-items: center;
    justify-content: center;
}
.inactive{
    background: #ffffff;
    width: 70px;
}
.roleTags{
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: var(--Feedback-Solid-Informational, #0679C3);
    width: fit-content;
}
.roleTagsText{
    color: var(--Text-Inverse, #FFF);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
.tag{
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    width: fit-content
}
.successful{
    color: #007F26;
    background: #DEFAE6;
}
.failed{
    color: #D51F31;
    background: #FDE7E7; 
}
.processing{
    color: #F3C233;
    background: #FFF3CE;
}
.requested{
    color: #0679C3;
    background: #cfe2f3
}
.icon{
    width: 12px;
    height: 12px;
}
.genericBadge{
    display: flex;
    width: fit-content;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #0679C3;
    color: var(--Text-Inverse, #FFF);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}