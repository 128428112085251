.breadcrumb {
    display: flex;
    align-items: center;
    padding: 0px 8px;
  }
  
  .breadcrumbList {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 8px
  }
  
  .breadcrumbItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .breadcrumbLink {
    color: var(--Text-Subtle, #757575);
    font-family: "McKinseySans-Light", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    text-decoration: none;
  }
  
  
  .breadcrumbCurrent {
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .breadcrumbSeparator {
    margin: 0 5px;
    color: #777;
  }
  