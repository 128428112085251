.container{
    border-radius: 8px;
    padding: 18px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    height: 13.125rem;
    display: flex;
    flex-direction: column;
}
.customText{
    color: #2B5580;
    font-size: 16px;
    font-family: "MckinseySans-Regular", sans-serif;
    margin: 15px 0;
}
.questionContainer{
    display: flex;
    align-items: center;
    gap: 14px;
}
.sliderContainer{
    margin-top: 20px;
}
.customWordText{
    font-size: 12px;
    color: #333333;
    font-family: "MckinseySans-Medium", sans-serif;
}
.loader{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.filterText{
    margin-bottom: 0.5rem;
    color: #757575;
    font-family: "MckinseySans-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}