.infoText{
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
}
.container{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    font-family: "MckinseySans-Regular", sans-serif;
    width: 48.75rem;
}
.comment{
    width: 58%;
}
.imageContainer{
    width: 42%;
}
.uploaderContainer{
    border-style: dashed;
    border-radius: 4px;
    color: #82A6C9;
    height: 170px;
    background: #F8F8F8;
    width: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feedback{
    margin-bottom: 10px;
}
.feedback::after{
    content: "*";
    color: red;
}
.errorText{
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
}
.errorText::before{
    content: "*";
    color: red; 
}