.container{
    width: 100%;
}
.userTable{
    width: 100%;
}
.inputContainer{
    display: flex;
    margin-bottom: 26px;
}
.search{
    display: flex;
    justify-content: flex-start;
    width: 90%;
}
.buttonContainer{
    display: flex;
    justify-content: flex-end;
    width: 10%;
}
.pagination{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}
.breadcrumbContainer{
    display: flex;
    flex-direction: column;
}
.deleteButtonContainer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}
.surveyContainer{
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem;
    width: 100%;
}
.surveyText{
    display: flex;
    justify-content: flex-start;
    color: var(--Text-Heading, #000);
    font-family: "McKinseySans-Regualar", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    width: 90%;
}
.assignButton{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    width: 10%;
}
.loader{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
.backButton{
    border: none;
    background: none;
    display: flex;
    gap: 8px;
}
.permissionsContainer{
    display: grid;
    grid-template-columns: 1fr minmax(400px, auto);
    margin-top: 2rem;
    gap: 3rem;
}
.demographicContainer{
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}
.demographicText{
    display: flex;
    justify-content: flex-start;
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.demographicValuesHeader{
    display: flex;
    color: var(--Text-Subtle, #757575);
    font-family: "McKinseySans-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    gap: 0.5rem;
}
.demographicValues{
    display: flex;
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Medium", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.input{
    width: 520px;
}
.buttons{
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    margin-top: 3rem;
}
.filterKey{
    display: flex;
}
.filterValues{
    display: flex;
    color: var(--Text-Body, #333);
    font-family: "McKinseySans-Light", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.noSurvey{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Core-Deep-Blue-900, #051C2C);
    font-family: "MckinseySans-Regular";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    margin-top: 3rem;
}
