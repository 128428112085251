.survey{
    display: flex;
    height: 90%;
    width: 100%;
    flex-direction: column;
}
.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid #b3b3b3;
    border-radius: 22px;
    width: 22%;
    padding: 0px 16px;
    height: 28px;
}
.searchInput{
    border: none;
    outline: none;
    color: #757575;
    font-size: 14px;
    font-family: "MckinseySans-Regular", sans-serif;
    flex: 1;
    font-weight: 400;
    height: 26px;
}
.surveyContainer{
    display: flex;
    margin-top: 30px;
}
.card{
    width: 424px;
    height: 228px;
    box-shadow: 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    border-radius: 8px;
    border: 1px rgba(5, 28, 44, 0.15);
}
.cardHeader{
    background: #E6F2F9;
    height: 27%;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000000;
    font-size: 18px;
    font-family: "MckinseySans-Medium", sans-serif;
    padding-left: 24px;
}
.container{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 40px;
    flex-direction: column;
}
.pagination{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}